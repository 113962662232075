<template>
  <v-card>
    <v-card-title>Send Text to {{ user.phoneNumber }}</v-card-title>
    <v-card-text>
      <v-textarea
        solo
        v-model="message"
        v-if="selectedUser.receiveTextNotifications"
        label="Message"
      ></v-textarea>
      <div v-else>This user has text messages disabled.</div>
      <v-btn :disabled="!message" color="primary" block @click="sendMessage"
        >Send Message</v-btn
      >
    </v-card-text>
    <v-overlay z-index="99999" :value="overlay">
      <v-chip v-if="overlay" color="primary" dark label class="ml-4">
        <v-icon left>
          mdi-checkbox-marked-outline
        </v-icon>
        Sent
      </v-chip>
    </v-overlay>
  </v-card>
</template>

<script>
import * as fb from '@/firebase'
export default {
  name: 'SendTextMessageDialog',
  props: ['user'],
  data() {
    return {
      overlay: false,
      selectedUser: {},
      allowMessages: null,
      proxyName: '',
      message: ''
    }
  },
  watch: {
    overlay: function() {
      setTimeout(
        function() {
          this.overlay = false
          this.$emit('closeSendMessage')
        }.bind(this),
        1000
      )
    }
  },
  async mounted() {
    this.$bind(
      'selectedUser',
      fb.db.collection('Patients').doc(this.user.userId)
    )
    const proxy = await fb.db
      .collection('Proxies')
      .doc(fb.auth.currentUser.uid)
      .get()

    if (proxy.data().role === 'Doctor') {
      this.proxyName = `Dr. ${proxy.data().fname} ${proxy.data().lname}`
    } else if (proxy.data().role === 'Nurse') {
      this.proxyName = `Nurse ${proxy.data().fname} ${proxy.data().lname}`
    } else {
      this.proxyName = `${proxy.data().fname} ${proxy.data().lname}`
    }
  },
  methods: {
    sendMessage() {
      if (this.selectedUser.receiveTextNotifications) {
        const sendTextMessage = fb.functions.httpsCallable('sendTextMessage')

        try {
          sendTextMessage({
            userId: this.user.userId,
            messageBody: `${this.proxyName}: \n${this.message}`
          })
          this.overlay = true
        } catch (error) {
          console.log(error)
        }
      }
    }
  }
}
</script>
