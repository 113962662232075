<template>
  <v-card-text>
    <v-form v-if="form.sessionLink" ref="form" v-model="valid" lazy-validation>
      <v-radio-group v-model="radioGroup">
        <v-radio label="SMS Text"></v-radio>
        <v-radio label="Email"></v-radio>
      </v-radio-group>
      <v-text-field
        v-if="radioGroup == 0"
        v-model="form.phoneNumber"
        label="Phone Number"
        dense
        outlined
        rounded
        required
        :rules="phoneRules"
      ></v-text-field>
      <v-text-field
        v-if="radioGroup == 1"
        v-model="form.email"
        label="Email"
        dense
        outlined
        rounded
        required
        :rules="emailRules"
      ></v-text-field>
      <v-textarea
        outlined
        rounded
        v-model="form.message"
        label="Message"
        required
        :rules="rules"
      ></v-textarea>
      <v-text-field
        v-model="form.sessionLink"
        label="Session Link"
        dense
        outlined
        rounded
        required
        :rules="rules"
      ></v-text-field>
      <v-btn color="primary" block @click="send()" :loading="loading"
        >Send Invite</v-btn
      >
    </v-form>

    <div v-if="!form.sessionLink">
      <div class="mb-2 error--text text-md-subtitle-2">
        You will need to set your custom doctor link first.
      </div>

      <v-btn color="primary" @click="navigate()" block>
        Go to Settings
      </v-btn>
    </div>
    <v-chip v-if="indicate" color="grey" outlined label>
      <v-icon left>
        mdi-checkbox-marked-outline
      </v-icon>
      Saved
    </v-chip>
    <v-chip v-if="error" block color="error" outlined label class="mt-4">
      {{ errorMsg }}
    </v-chip>
  </v-card-text>
</template>

<script>
import * as fb from '@/firebase'
export default {
  name: 'InviteMessageDialog',
  props: ['user'],
  data() {
    return {
      loading: false,
      valid: false,
      indicate: false,
      error: false,
      errorMsg: '',
      doctorLink: null,
      linkPreview: process.env.VUE_APP_URL,
      radioGroup: 0,
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ],
      rules: [v => !!v || 'Required'],
      phoneRules: [
        value => !!value || !value,
        value => {
          const pattern = /^\d{3}-\d{3}-\d{4}$/
          const pattern2 = /^\d{10}$/

          return (
            pattern.test(value) ||
            pattern2.test(value) ||
            'Invalid phone number'
          )
        }
      ],
      linkRule: [
        value => !!value || 'Required.',
        value => {
          const pattern = /^(?=.*[a-z])[a-z0-9]{4,20}$/
          return pattern.test(value) || 'Lowercase letters and numbers only'
        }
      ],
      form: {
        sessionLink: null,
        email: null,
        phoneNumber: null,
        message: `Please click to join my telemedicine session.
Do not reply`
      }
    }
  },
  watch: {
    doctorLink: function() {
      if (this.doctorLink.doctorLink) {
        this.linkPreview = process.env.VUE_APP_URL + this.doctorLink.doctorLink
      }
    },
    indicate: function() {
      setTimeout(
        function() {
          this.indicate = false
        }.bind(this),
        2000
      )
    },
    error: function() {
      setTimeout(
        function() {
          this.error = false
        }.bind(this),
        4000
      )
    }
  },
  async created() {
    await this.$bind('doctorLink', fb.db.doc(`Doctor_Links/${this.user.id}`))

    if (this.doctorLink) {
      this.linkPreview = process.env.VUE_APP_URL + this.doctorLink.doctorLink
      this.form.sessionLink =
        process.env.VUE_APP_URL + this.doctorLink.doctorLink

      this.form.message = `Please click to join your telemedicine appointment with ${this.user.displayName}.
${this.form.sessionLink}
Do not reply`
    }
  },
  methods: {
    async send() {
      this.loading = true
      if (this.$refs.form.validate()) {
        if (this.radioGroup == 0) {
          const sendDirectTextMessage = fb.functions.httpsCallable(
            'sendDirectTextMessage'
          )
          try {
            await sendDirectTextMessage({
              phoneNumber: this.form.phoneNumber,
              providerName: this.doctorLink.userName,
              messageBody: `VPExam
${this.form.message}`,
              link: this.form.sessionLink
            })
            this.loading = false
            this.$emit('close')
          } catch (error) {
            this.errorMsg = error.message
            this.error = true
            this.loading = false
          }
        } else {
          const providerMessageEmailNotification = fb.functions.httpsCallable(
            'providerMessageEmailNotification'
          )
          try {
            await providerMessageEmailNotification({
              email: this.form.email,
              providerName: this.doctorLink.userName,
              customMessage: this.form.message,
              link: this.form.sessionLink
            })
            this.loading = false
            this.$emit('close')
          } catch (error) {
            this.errorMsg = error.message
            this.error = true
            this.loading = false
          }
        }
      } else {
        this.errorMsg = 'Invalid Fields'
        this.error = true
        this.loading = false
      }
    },
    navigate() {
      if (this.$route.path !== '/settings') {
        this.$router.push('/settings')
        this.$emit('close')
      } else {
        this.$emit('close')
      }
    }
  }
}
</script>
