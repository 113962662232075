<template>
  <div id="BAA">
    <div id="page1">
      <div class="doc-h1">Business Associate Agreement</div>

      <div class="p1">
        This Business Associate Agreement ("Agreement") is entered into this
        21st day of August, 2017 (the “Effective Date”), by and between TCS, an
        Ohio Professional Corporation ("Covered Entity") and TeleHealth Care
        Solutions, a telemedicine service provider ("Business Associate").
        Covered Entity and Business Associate, collectively, may be referred to
        herein as the "Parties".
      </div>

      <div class="p1">
        Covered Entity is a Business organization that provides medical services
        with a principal place of business at 92 Highland.
      </div>

      <div class="section">
        <div class="doc-h2">ARTICLE 1</div>
        <div class="doc-h2">INTRODUCTION</div>
      </div>

      <div class="p1">
        1.1 Covered Entity and Business Associate enter into this Agreement to
        comply with the requirements of Health Insurance Portability and
        Accountability Act of 1996 ("HIPAA"), as amended, including the privacy,
        security, breach notification and enforcement rules at 45 C.F.R. Part
        160 and Part 164, as well as the Health Information Technology for
        Economic and Clinical Health Act, enacted as part of the American
        Recovery and Reinvestment Act of 2009 ("HITECH"), as amended, and other
        applicable federal and state laws (collectively the "HIPAA Rules").
      </div>

      <div class="p1">
        1.2 This Agreement is intended to ensure that Business Associate will
        establish and implement appropriate safeguards for certain individually
        identifiable Protected Health Information relating to patients of
        Covered Entity ("PHI" as that term is defined below) that Business
        Associate may receive, create, maintain, use or disclose in connection
        with certain functions, activities and services that Business Associate
        performs for Covered Entity. The functions, activities and services that
        Business Associate performs for Covered Entity are defined in one or
        more agreements between the Parties (the "Underlying Agreements").
      </div>

      <div class="section">
        <div class="doc-h2">ARTICLE 2</div>
        <div class="doc-h2">DEFINITIONS</div>
      </div>

      <div class="p1">
        2.1 Terms used but not otherwise defined in this Agreement shall have
        the same meaning as those terms in the HIPAA Rules, which definitions
        are incorporated in this Agreement by reference
      </div>

      <div class="p1">2.2 For purposes of this Agreement:</div>

      <div class="p1">
        2.2.1 "Electronic Protected Health Information" or "ePHI" shall have the
        meaning given to such term under the Privacy Rule and the Security Rule,
        including, but not limited to, 45 C.F.R. 160.103, as applied to the
        information created, received, maintained or transmitted by Business
        Associate from or on behalf of Covered Entity
      </div>

      <div class="p1">
        2.2.2 "Individual" shall have the same meaning given to such term in 45
        C.F.R. § 160.103 and shall include a person who qualifies as a personal
        representative in accordance with 45 C.F.R. § 164.502(g).
      </div>

      <div class="p1">
        2.2.3 "Protected Health Information" or "PHI" shall have the meaning
        given to such term in 45 C.F.R. 160.103, limited to the information
        created, received, maintained or transmitted by Business Associate from
        or on behalf of Covered Entity.
      </div>

      <div class="p1">
        2.2.4 "Privacy Rule" shall mean the Standards for Privacy of
        Individually Identifiable Health Information published in 45 C.F.R.
        Parts 160 and 164, Subparts A and E. 2.2.5 "Required by Law" shall have
        the meaning given to such term in 45 C.F.R. 164.103.
      </div>

      <div class="p1">
        2.2.6 "Secretary" shall mean the Secretary of the Department of Health
        and Human Services or his or her designee.
      </div>

      <div class="p1">
        2.2.7 "Security Rule" shall mean the Security Standards at 45 C.F.R.
        Part 160 and Part 164, Subparts A and C.
      </div>

      <div class="section">
        <div class="doc-h2">ARTICLE 3</div>
        <div class="doc-h2">GENERAL OBLIGATIONS OF BUSINESS ASSOCIATE</div>
      </div>

      <div class="p1">
        3.1 Use and Disclosure. Business Associate agrees not to use or disclose
        PHI, other than as permitted or required by this Agreement or as
        Required By Law. To the extent Business Associate is carrying out one or
        more of Covered Entity's obligations under the Privacy Rule pursuant to
        the terms of the Underlying Agreement or this Agreement, Business
        Associate shall comply with the requirements of the Privacy Rule that
        apply to Covered Entity in the performance of such obligation(s).
      </div>

      <div class="p1">
        3.2 Appropriate Safeguards. Business Associate shall use appropriate
        physical, technical and administrative safeguards, and shall comply with
        the Security Rule with respect to ePHI, to prevent use or disclosure of
        PHI other than as provided for by this Agreement or as Required by Law.
      </div>

      <div class="p1">
        3.3 Mitigation. Business Associate agrees to mitigate, to the extent
        practicable, any harmful effect that is known to Business Associate as a
        result of a use or disclosure of PHI by Business Associate in violation
        of this Agreement's requirements, including a Breach of Unsecured PHI.
      </div>

      <div class="p1">
        3.4 Breach Reporting. Business Associate shall report to Covered Entity
        any use or disclosure of PHI not permitted under this BAA, Breach of
        Unsecured PHI or SecurityIncident, without unreasonable delay, and in
        any event no more than thirty (30) days following discovery; provided,
        however, that the Parties acknowledge and agree that this Section
        constitutes notice by Business Associate to Covered Entity of the
        ongoing existence and occurrence of attempted but Unsuccessful Security
        Incidents (as defined below) for which notice to Covered Entity by
        Business Associate shall be required only upon request. "Unsuccessful
        Security Incidents" shall include, but not be limited to, pings and
        other broadcast attacks on Business Associate's firewall, port scans,
        unsuccessful log- on attempts, denials of service and any combination of
        the above, so long as no such incident results in unauthorized access,
        use or disclosure of PHI. Business Associate's notification to Covered
        Entity of a Breach shall include: (i) the identification of each
        individual whose Unsecured PHI has been, or is reasonably believed by
        Business Associate to have been, accessed, acquired or disclosed during
        the Breach; and (ii) any particulars regarding the Breach that Covered
        Entity would need to include in its notification, as such particulars
        are identified in 45 C.F.R. § 164.404.
      </div>

      <div class="p1">
        3.5 Subcontractors. In accordance with 45 C.F.R. §§ 164.502(e)(1)(ii)
        and 164.308(b)(2), if applicable, Business Associate shall enter into a
        written agreement with any agent or subcontractor that creates,
        receives, maintains or transmits PHI on behalf of the Business Associate
        for services provided to Covered Entity, which provides that the agent
        agrees to substantially the same restrictions, conditions and
        requirements that apply to the Business Associate with respect to such
        information
      </div>

      <div class="p1">
        3.6 Access to PHI. Business Associate agrees to provide access to PHI in
        a Designated Record Set to the Covered Entity. If an Individual makes a
        request for access pursuant to 45 C.F.R. § 164.524 directly to Business
        Associate, or inquires about his or her right to access, Business
        Associate shall forward it to Covered Entity. Any response to such
        request shall be the responsibility of Covered Entity.
      </div>

      <div class="p1">
        3.7 Minimum Necessary Requirement. Business Associate agrees that when
        requesting, using or disclosing PHI in accordance with 45 C.F.R. §
        502(b)(1) that such request, use or disclosure shall be to the minimum
        extent necessary, including the use of a "limited data set" as defined
        in 45 C.F.R. § 164.514(e)(2), to accomplish the intended purpose of such
        request, use or disclosure, as interpreted under related guidance issued
        by the Secretary from time to time.
      </div>

      <div class="p1">
        3.8 Amendment of PHI. Business Associate agrees to make PHI contained in
        a Designated Record Set available to Covered Entity for amendment
        pursuant to 45 C.F.R. § 164.526. If an Individual makes a request for
        amendment pursuant to 45 C.F.R. § 164.526 directly to Business
        Associate, or inquires about his or her right to access, Business
        Associate shall forward it to Covered Entity. Any response to such
        request shall be the responsibility of Covered Entity.
      </div>

      <div class="p1">
        3.9 Accounting of Disclosures. Business Associate shall provide to
        Covered Entity information collected in accordance with Section 3.11 of
        this Agreement, to permit Covered Entity to respond to a request by an
        Individual for an accounting of disclosuresof PHI in accordance with 45
        C.F.R. § 164.528. If any Individual requests an accounting of
        disclosures of PHI directly from Business Associate, Business Associate
        shall forward such request to Covered Entity. Any response to such
        request shall be the responsibility of Covered Entity.
      </div>

      <div class="p1">
        3.10 Access to Policies and Records. Business Associate agrees to make
        its internal practices, books and records, including policies and
        procedures regarding PHI, relating to the use and disclosure of PHI and
        Breach of any Unsecured PHI received from Covered Entity, or created or
        received by the Business Associate on behalf of Covered Entity,
        available to the Secretary for the purpose of the Secretary determining
        Covered Entity's compliance with the HIPAA Rules.
      </div>

      <div class="p1">
        3.11 Documentation of Disclosures. Business Associate shall document
        such disclosures of PHI and information related to such disclosures as
        would be required for Covered Entity to respond to a request by an
        Individual for an accounting of disclosures of PHI in accordance with 45
        C.F.R. § 164.528. Business Associate shall document, at a minimum, the
        following information ("Disclosure Information"): (i) the date of the
        disclosure, (ii) the name and, if known, the address of the recipient of
        the PHI, (iii) a brief description of the PHI disclosed, (iv) the
        purpose of the disclosure that includes an explanation of the basis for
        such disclosure, and (v) any additional information required under the
        HITECH Act and any implementing regulations.
      </div>

      <div class="section">
        <div class="doc-h2">ARTICLE 4</div>
        <div class="doc-h2">
          PERMITTED USES AND DISCLOSURES BY BUSINESS ASSOCIATE
        </div>
      </div>

      <div class="p1">
        4.1 General Uses and Disclosures. Business Associate agrees to receive,
        create, use or disclose PHI only as permitted by this Agreement, the
        HIPAA Rules, and only in connection with providing services to Covered
        Entity; provided that the use or disclosure would not violate the
        Privacy Rule if done by Covered Entity, except as set forth in this
        Article 4.
      </div>

      <div class="p1">
        4.2 Business Associate may use or disclose PHI as Required By Law.
      </div>

      <div class="p1">
        4.3 Except as otherwise provided in this Agreement, Business Associate
        may:
      </div>

      <div class="p1">
        4.3.1 Use PHI for the proper management and administration of Business
        Associate, or to carry out its legal responsibilities.
      </div>

      <div class="p1">
        4.3.2 Disclose PHI for the proper management and administration of
        Business Associate or to carry out legal responsibilities of Business
        Associate, provided that the disclosures are Required by Law, or
        Business Associate obtains prior written reasonable assurances from the
        person to whom the information is disclosed
      </div>

      <div class="p1">
        that the information will remain confidential and used or further
        disclosed only as Required by Law or for the purposes for which it was
        disclosed to the person, and the person notifies the Business Associate
        of any instances of which it is aware in which the confidentiality of
        the information has been breached, in accordance with the breach
        notification requirements of this Agreement.
      </div>

      <div class="p1">
        4.3.3 Use PHI to provide Data Aggregation Services to Covered Entity as
        permitted under the HIPAA Rules.
      </div>

      <div class="section">
        <div class="doc-h2">ARTICLE 5</div>
        <div class="doc-h2">OBLIGATIONS OF COVERED ENTITY</div>
      </div>

      <div class="p1">5.1 Covered Entity shall:</div>

      <div class="p1">
        5.1.1 Notify Business Associate in writing of any limitation(s) in its
        Notice of Privacy Practices in accordance with 45 C.F.R. 164.520, to the
        extent that such limitation may affect Business Associate's use or
        disclosure of PHI.
      </div>

      <div class="p1">
        5.1.2 Notify Business Associate in writing of any restriction to the use
        or disclosure of PHI that Covered Entity has agreed to in accordance
        with 45 C.F.R. § 164.522, to the extent that such changes may affect
        Business Associate's use or disclosure of PHI.
      </div>

      <div class="p1">
        5.1.3 Notify Business Associate in writing of any changes in or
        revocation of permission by an individual to use or disclose his or her
        PHI, to the extent that such change or revocation may affect Business
        Associate's permitted or required uses and disclosures of PHI.
      </div>

      <div class="p1">
        5.2 Covered Entity shall not request Business Associate to use or
        disclose PHI in any manner that would not be permissible under the
        Privacy Rule or the Security Rule if done by Covered Entity, except as
        provided under Article 4 of this Agreement.
      </div>

      <div class="section">
        <div class="doc-h2">ARTICLE 6</div>
        <div class="doc-h2">TERM AND TERMINATION</div>
      </div>

      <div class="p1">
        6.1 Term. This Agreement shall be in effect as of the Effective Date and
        shall terminate on the earlier of the date that:
      </div>

      <div class="p1">
        6.1.1Either party terminates for cause as authorized under Section 6.2.
        or
      </div>

      <div class="p1">
        6.1.2 All PHI received from Covered Entity, or created or received by
        Business Associate on behalf of Covered Entity, is destroyed or returned
        to Covered Entity. If it is determined, to be infeasible to return or
        destroy PHI, protections are extended to such information in accordance
        with Section 6.3.Termination for Cause. Upon either Party’s knowledge of
        material breach by the other Party, the non-breaching Party shall
        provide an opportunity for the breaching Party to cure the breach or end
        the violation. If the breaching Party does not cure the breach or end
        the violation within the timeframe specified by the non-breaching Party,
        or if a material term of this Agreement has been breached and a cure is
        not possible, the non-breaching Party may terminate this Agreement and
        the Underlying Agreement(s), if any, upon written notice to the
        breaching Party.
      </div>

      <div class="p1">
        6.3 Obligations of Business Associate Upon Termination. Upon termination
        of this Agreement for any reason, Business Associate, with respect to
        PHI received from Covered Entity, or created, maintained, or received by
        Business Associate on behalf of Covered Entity, shall:
      </div>

      <div class="p1">
        6.3.1 Retain only that PHI that is necessary for Business Associate to
        continue its proper management and administration or to carry out its
        legal responsibilities;
      </div>

      <div class="p1">
        6.3.2 Return to Covered Entity or, if agreed to by Covered Entity in
        writing, destroy the remaining PHI that the Business Associate still
        maintains in any form;
      </div>

      <div class="p1">
        6.3.3 Continue to use appropriate safeguards and comply with Subpart C
        of 45 C.F.R. Part 164 with respect to ePHI to prevent use or disclosure
        of the PHI, other than as provided for in this Section 6, for as long as
        Business Associate retains the PHI;
      </div>

      <div class="p1">
        6.3.4 Limit further uses and disclosures of such PHI to those purposes
        that make the return or destruction infeasible, for so long as Business
        Associate maintains such PHI;
      </div>

      <div class="p1">
        6.3.5 Return to Covered Entity or destroy the PHI retained by Business
        Associate when it is no longer needed by Business Associate for its
        proper management and administration or to carry out its legal
        responsibilities.
      </div>

      <div class="section">
        <div class="doc-h2">ARTICLE 7</div>
        <div class="doc-h2">MISCELLANEOUS</div>
      </div>

      <div class="p1">
        7.1 Amendment. The Parties agree to take such action as is necessary to
        amend this Agreement to comply with the requirements of the HIPAA Rules
        and any other applicable law.
      </div>

      <div class="p1">
        7.2 Survival. The respective rights and obligations of Business
        Associate under Article 6 of this Agreement shall survive the
        termination of this Agreement.
      </div>

      <div class="p1">
        7.3 Regulatory References. A reference in this Agreement to a section of
        the HIPAA Rules means the section as in effect or amended.
      </div>

      <div class="p1">
        7.4 Interpretation. This Agreement shall be interpreted in the following
        manner:
      </div>

      <div class="p1">
        7.4.1 Any ambiguity shall be resolved in favor of a meaning that permits
        Covered Entity and Business Associate to comply with the HIPAA Rules.
      </div>

      <div class="p1">
        7.4.2 Any inconsistency between the Agreement's provisions and the HIPAA
        Rules, including all amendments, as interpreted by the Department of
        Health and Human Services, court or another regulatory agency with
        authority over the Parties, shall be interpreted according to the
        interpretation of the Department of Health and Human Services, the court
        or the regulatory agency.
      </div>

      <div class="p1">
        7.4.3 Any provision of this Agreement that differs from those mandated
        by the HIPAA Rules, but is nonetheless permitted by the HIPAA Rules,
        shall be adhered to as stated in this Agreement.
      </div>

      <div class="p1">
        7.5 Entire Agreement, Severability. This Agreement constitutes the
        entire agreement between the Parties related to the subject matter of
        this Agreement, except to the extent that the Underlying Agreement(s),
        if any, impose more stringent requirements related to the use and
        protection of PHI upon Business Associate. This Agreement supersedes all
        prior negotiations, discussions, representations or proposals, whether
        oral or written. This Agreement may not be modified unless done so in
        writing and signed by a duly authorized representative of both Parties.
        If any provision of this Agreement, or part thereof, is found to be
        invalid, the remaining provisions shall remain in effect.
      </div>

      <div class="p1">
        7.6 Assignment. This Agreement will be binding on the successors and
        assigns of Covered Entity and Business Associate.
      </div>

      <div class="p1">7.7</div>

      <div class="p1">Multiple Counterparts.</div>

      <div class="p1">
        This Agreement may be executed in two or more counterparts, each of
        which shall be deemed an original.
      </div>

      <div class="p1">7.8</div>

      <div class="p1">Governing Law.</div>

      <div class="p1">
        Except to the extent preempted by federal law, this Agreement shall be
        governed by and construed in accordance with the laws of the state in
        which the Covered Entity's principal place of business is located.
      </div>

      <div class="p1">
        IN WITNESS WHEREOF, the Parties hereto have executed this Agreement as
        of the Effective Date.
      </div>

      <div class="p1">COVERED ENTITY</div>

      <div class="p1">
        <span>By: </span>
        <span class="underline">University Hospitals of Cleveland</span>
      </div>
      <div class="p1">
        <span>Name: </span><span class="underline">{{ covered.name }}</span>
      </div>
      <div class="p1">
        <span>Title: </span>
        <span class="underline">{{ covered.title }}</span>
      </div>
      <div class="p1">
        <span>Date: </span>
        <span class="underline">{{ covered.date }}</span>
      </div>
      <br />
      <div class="p1">
        BUSINESS ASSOCIATE
      </div>
      <div class="p1">
        <span>By: </span>
        <span class="underline">TeleHealth Care Solutions, LLC</span>
      </div>
      <div class="p1">
        <span>Name: </span>
        <span class="underline">James Cireddu</span>
      </div>
      <div class="p1">
        <span>Title: </span>
        <span class="underline">CEO</span>
      </div>
      <div class="p1"></div>
      <div class="p1">
        <span>Date: </span>
        <span class="underline">{{ associate.date }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import * as fb from '../firebase'
import * as moment from 'moment'
export default {
  name: 'BAA',
  data() {
    return {
      covered: {
        name: 'N/A',
        title: 'N/A',
        date: 'N/A'
      },
      associate: {
        date: 'N/a'
      }
    }
  },
  async mounted() {
    const currentUser = await fb.proxiesCollection
      .doc(fb.auth.currentUser.uid)
      .get()

    this.covered.name = currentUser.data().displayName
    this.covered.title = currentUser.data().role

    const practice = await fb.practicesCollection
      .doc(currentUser.data().practiceId)
      .get()

    const date = practice.data().dateCreated

    this.covered.date = moment(date.toDate()).format('MM/DD/YYYY')
    this.associate.date = moment(date.toDate()).format('MM/DD/YYYY')
  }
}
</script>

<style>
#BAA {
  max-width: 737px;
  margin: 0 auto;
}
.underline {
  text-decoration: underline;
}
.center {
  text-align: center;
}
.doc-h1 {
  text-align: center;
  font: bold 20px 'Times';
  line-height: 25px;
}
.doc-h2 {
  text-align: center;
  font: bold 18px 'Times';
  line-height: 20px;
  margin-top: 24px;
}

.p1 {
  font: 15px 'Times';
  line-height: 20px;
  text-align: justify;
  padding-right: 0;
  margin-top: 25px;
  margin-bottom: 0px;
}
</style>
