<template>
  <v-text-field
    rounded
    filled
    single-line
    clearable
    dense
    hide-details
    placeholder="Search"
    background-color="white"
    prepend-inner-icon="mdi-magnify"
    class="search"
    :search="search"
    style="width: 350px;"
  ></v-text-field>
</template>

<script>
export default {
  data() {
    return {
      search: ''
    }
  }
}
</script>
