<template>
  <div v-if="currentUser">
    <v-card rounded="xl" class="mt-4 pt-4 pb-4">
      <v-card-title>Signature</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <VueSignaturePad
              id="sig-canvas"
              ref="signaturePad"
              width="100%"
              height="160px"
              :options="options"
            />
          </v-col>
          <v-col cols="12">
            <v-btn color="primary" :loading="loadingSignature" @click="save"
              >Save</v-btn
            >
            <v-btn color="primary" class="ml-2" outlined @click="undo"
              >Undo</v-btn
            >
          </v-col>
        </v-row>
        <img
          v-if="currentUser.signature"
          style="max-width: 100%;"
          :src="currentUser.signature"
          alt=""
        />
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import * as fb from '@/firebase'
import Axios from 'axios'
export default {
  name: 'Signature',
  props: ['user'],
  data() {
    return {
      currentUser: null,
      options: {
        penColor: '#000'
      },
      loadingSignature: false
    }
  },
  watch: {
    user: {
      immediate: true,
      async handler() {
        if (this.user) {
          if (this.user.signature) {
            this.currentUser = { id: this.user.id, ...this.user }
          } else {
            this.currentUser = {
              id: this.user.id,
              signature: null,
              ...this.user
            }
          }
        }
      }
    }
  },
  created() {},
  mounted() {},
  methods: {
    undo() {
      this.$refs.signaturePad.undoSignature()
    },
    save() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature()
      if (!isEmpty) {
        this.loadingSignature = true
        var image = new Image()
        image.src = data
        Axios.get(image.src, { responseType: 'blob' }).then(response => {
          const imgBlob = new Blob([response.data])
          this.uploadSignature(imgBlob)
        })
      }
    },
    uploadSignature(file) {
      if (!file) return

      const reader = new FileReader()

      reader.onload = event => {
        this.currentUser.signature = event.target.result
      }

      reader.readAsDataURL(file)

      const storageRef = fb.storage.ref()
      const photoRef = storageRef.child(
        `Proxies/${this.currentUser.id}/photos/signature.png`
      )
      const uploadTask = photoRef.put(file)

      uploadTask.on(
        'state_changed',
        snapshot => {
          console.log(snapshot.state)
        },
        error => {
          console.log(error)
        },
        async () => {
          const downloadURL = await uploadTask.snapshot.ref.getDownloadURL()
          await fb.db
            .doc(`Proxies/${this.currentUser.id}`)
            .set({ signature: downloadURL }, { merge: true })
          this.loadingSignature = false
        }
      )
      // this.indicate = true
    }
  }
}
</script>
<style>
#sig-canvas {
  position: relative;
  border: 2px dotted #cccccc;
  border-radius: 15px;
  cursor: crosshair;
}
</style>
