<template>
  <v-container v-if="authUser">
    <h1>User Settings</h1>
    <v-card v-if="currentUser" rounded="xl" class="mt-4 pt-4 pb-4">
      <v-container
        style="max-width: 753px !important; margin: 0 auto;"
        fill-height
      >
        <v-row align="center" justify="center">
          <v-col align="center" justify="center" cols="12" md="4" sm="4">
            <v-avatar size="150">
              <img v-if="!currentUser.photoURL" src="../assets/avatar.png" />
              <img v-else :src="currentUser.photoURL" />
            </v-avatar>
            <v-file-input
              style="margin-top: 32px;"
              accept="image/*"
              placeholder="Choose a photo"
              label="Photo"
              outlined
              rounded
              dense
              @change="uploadFile"
            ></v-file-input>
          </v-col>
          <!-- right -->
          <v-col>
            <v-col cols="12" md="12">
              <v-card-text
                class="title black--text pl-0"
                :style="$mq === 'mobile' ? '' : 'font-size: 2em !important;'"
                >{{ user.email }}</v-card-text
              >

              <v-text-field
                v-model="currentUser.fname"
                label="First Name"
                outlined
                rounded
                dense
              ></v-text-field>

              <v-text-field
                v-model="currentUser.lname"
                label="Last Name"
                outlined
                rounded
                dense
              ></v-text-field>

              <v-text-field
                v-model="currentUser.phoneNumber"
                label="Phone Number"
                outlined
                rounded
                dense
                :rules="phoneRules"
              ></v-text-field>

              <v-text-field
                :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show2 ? 'text' : 'password'"
                value="wqfasds"
                class="input-group--focused"
                @click:append="show2 = !show2"
                v-model="currentUser.password"
                label="Password"
                outlined
                rounded
                dense
              ></v-text-field>

              <v-select
                v-if="currentUser.role === 'Doctor'"
                v-model="specialtiesSelect"
                :items="specialtiesList"
                item-text="name"
                item-value="name"
                label="Select Specialty"
                return-object
                single-line
                outlined
                rounded
                dense
              ></v-select>

              <v-row
                ><v-col cols="12" md="8"
                  ><div class="black--text font-weight-bold">
                    Exam upload email notifications
                  </div></v-col
                >
                <v-col cols="12" md="4"
                  ><v-switch
                    v-model="currentUser.isExamEmail"
                    :label="currentUser.isExamEmail ? 'on' : 'off'"
                    color="success"
                    :value="currentUser.isExamEmail"
                    class="ma-0"
                    hide-details="hint"
                    :style="$mq === 'mobile' ? '' : 'float: right;'"
                  ></v-switch></v-col
              ></v-row>
              <v-row
                ><v-col cols="12" md="8"
                  ><div class="black--text font-weight-bold">
                    Waiting room email notifications
                  </div></v-col
                >
                <v-col cols="12" md="4"
                  ><v-switch
                    v-model="currentUser.isWaitingEmail"
                    :label="currentUser.isWaitingEmail ? 'on' : 'off'"
                    color="success"
                    :value="currentUser.isWaitingEmail"
                    class="ma-0"
                    hide-details="hint"
                    :style="$mq === 'mobile' ? '' : 'float: right;'"
                  ></v-switch
                ></v-col>
              </v-row>
              <v-row
                ><v-col cols="12" md="8"
                  ><div class="black--text font-weight-bold">
                    Receive waiting room text notifications
                  </div></v-col
                >
                <v-col cols="12" md="4"
                  ><v-switch
                    v-model="currentUser.receiveTextNotifications"
                    :label="currentUser.receiveTextNotifications ? 'on' : 'off'"
                    color="success"
                    :value="currentUser.receiveTextNotifications"
                    class="ma-0"
                    hide-details="hint"
                    :style="$mq === 'mobile' ? '' : 'float: right;'"
                  ></v-switch
                ></v-col>
              </v-row>
              <v-row
                ><v-col cols="12" md="8"
                  ><div class="black--text font-weight-bold">
                    Receive exam upload text notifications
                  </div></v-col
                >
                <v-col cols="12" md="4"
                  ><v-switch
                    v-model="currentUser.isExamText"
                    :label="currentUser.isExamText ? 'on' : 'off'"
                    color="success"
                    :value="currentUser.isExamText"
                    class="ma-0"
                    hide-details="hint"
                    :style="$mq === 'mobile' ? '' : 'float: right;'"
                  ></v-switch
                ></v-col>
              </v-row>
            </v-col>
            <v-card-actions style="justify-content: flex-end;">
              <v-chip v-if="indicate" color="grey" outlined label class="mr-4">
                <v-icon left>
                  mdi-checkbox-marked-outline
                </v-icon>
                Updated
              </v-chip>
              <v-btn
                :block="$mq === 'mobile'"
                color="primary"
                :loading="loading"
                @click.native="update"
                >Save Changes</v-btn
              >
            </v-card-actions>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <Signature :user="authUser" />
    <v-card rounded="xl" class="mt-4 pt-4 pb-4">
      <v-container>
        <v-row>
          <v-col cols="6" md="6" class="d-flex align-center"
            ><v-card-title>BAA</v-card-title></v-col
          >

          <v-col cols="6" md="6" class="d-flex justify-end align-center">
            <v-btn color="primary" @click="printPdf">Print Document</v-btn>
          </v-col>
        </v-row>

        <v-row>
          <BAA />
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import ProxyLayout from '@/layouts/ProxyLayout'
import Signature from '@/components/Signature'
import { db, auth, storage } from '../firebase'
import html2pdf from 'html2pdf.js'
import BAA from '../components/BAA'
export default {
  name: 'UserSettings',
  components: {
    BAA,
    Signature
  },
  props: ['user'],
  data() {
    return {
      indicate: false,
      show2: false,
      loading: false,
      error: '',
      authUser: null,
      specialties: [],
      specialtiesSelect: { id: '', name: '' },
      specialtiesList: [],
      currentUser: null,
      phoneRules: [
        value => !!value || 'Required.',
        value => {
          const pattern = /^\d{3}-\d{3}-\d{4}$/
          const pattern2 = /^\d{10}$/
          return (
            pattern.test(value) ||
            pattern2.test(value) ||
            'Invalid phone number'
          )
        }
      ]
    }
  },
  async mounted() {
    this.$bind('specialties', db.collection(`Specialties`))
  },
  watch: {
    user: {
      immediate: true,
      async handler() {
        if (this.user) {
          this.authUser = this.user
          await this.$bind(
            'currentUser',
            db.doc(`Proxies/${this.authUser.id}`)
          ).then(() => {
            if (!this.currentUser.isExamEmail) {
              this.currentUser.isExamEmail = false
            }
            if (!this.currentUser.isWaitingEmail) {
              this.currentUser.isWaitingEmail = false
            }
            if (
              this.currentUser.role === 'Doctor' &&
              this.currentUser.specialty
            ) {
              this.specialtiesSelect = {
                id: this.currentUser.specialty.id,
                name: this.currentUser.specialty.name
              }
            }
          })
        }
      }
    },
    specialties: function() {
      for (const specialty of this.specialties) {
        this.specialtiesList.push({ id: specialty.id, name: specialty.name })
      }
    },
    indicate: function() {
      setTimeout(
        function() {
          this.indicate = false
        }.bind(this),
        2000
      )
    }
  },
  created() {
    this.$emit(`update:layout`, ProxyLayout)
  },
  methods: {
    printPdf() {
      window.scrollTo(0, 0)
      var element = document.getElementById('BAA')
      var opt = {
        margin: 1,
        filename: 'BAA.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'in', format: 'legal', orientation: 'portrait' }
      }
      html2pdf(element, opt)
    },
    async update() {
      this.loading = true
      this.currentUser.displayName =
        this.currentUser.fname + ' ' + this.currentUser.lname
      try {
        await this.updateUser({
          fname: this.currentUser.fname,
          lname: this.currentUser.lname,
          isExamEmail: this.currentUser.isExamEmail,
          isWaitingEmail: this.currentUser.isWaitingEmail,
          phoneNumber: this.currentUser.phoneNumber,
          receiveTextNotifications: this.currentUser.receiveTextNotifications,
          isExamText: this.currentUser.isExamText,
          specialty: {
            id: this.specialtiesSelect.id,
            name: this.specialtiesSelect.name
          }
        })
      } catch (error) {
        this.error = error.message
        console.log(this.error)
      }

      if (this.currentUser.password) {
        await auth.currentUser.updatePassword(this.currentUser.password)
      }

      this.loading = false
    },
    async updateUser(data) {
      await auth.currentUser.updateProfile(data)
      await db
        .doc(`Proxies/${this.currentUser.id}`)
        .update({ ...data, displayName: this.currentUser.displayName })
      this.indicate = true
    },
    uploadFile(file) {
      if (!file) return

      const reader = new FileReader()

      reader.onload = event => {
        this.currentUser.photoURL = event.target.result
      }

      reader.readAsDataURL(file)

      const storageRef = storage.ref()
      const photoRef = storageRef.child(
        `Proxies/${this.currentUser.id}/photos/${file.name}`
      )
      const uploadTask = photoRef.put(file)

      uploadTask.on(
        'state_changed',
        snapshot => {
          console.log(snapshot.state)
        },
        error => {
          console.log(error)
        },
        async () => {
          const downloadURL = await uploadTask.snapshot.ref.getDownloadURL()
          db.doc(`Proxies/${this.currentUser.id}`).set(
            { photoURL: downloadURL },
            { merge: true }
          )
        }
      )
      this.indicate = true
    }
  }
}
</script>
