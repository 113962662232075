<template>
  <div v-if="currentUser" class="home">
    <v-navigation-drawer
      v-model="drawer"
      width="250"
      persistent
      :mini-variant="miniVariant"
      :expand-on-hover="expandOnHover"
      fixed
      app
    >
      <v-list nav>
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                class="title primary--text d-flex justify-left h1"
              >
                <span
                  ><img
                    alt="Vue logo"
                    :src="siteMode === 'UH' ? logoUH : logoVP"
                  />
                </span>
                {{ siteMode === 'UH' ? 'VPExam' : 'VPExam' }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-list-item-group color="primary">
          <v-list-item to="/appointments">
            <v-list-item-icon>
              <v-icon>mdi-stethoscope</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Appointments</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="currentUser.isAdmin" to="/admin">
            <v-list-item-icon>
              <v-icon>mdi-account-star</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Admin</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="currentUser.role === 'Doctor'" to="/Settings">
            <v-list-item-icon>
              <v-icon>mdi-cog</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Settings</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-if="currentUser.role === 'Doctor'"
            link
            @click="inviteMessageDialog = true"
          >
            <v-list-item-icon>
              <v-icon>mdi-send</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Invite</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-list>
        <v-list-item-group color="primary">
          <v-list-item v-if="currentUser.role === 'Doctor'" to="/waiting-room">
            <v-list-item-icon>
              <v-icon>mdi-google-classroom</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Waiting Room</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <div v-if="waiting.length > 0">
            <v-list-item v-for="item in waiting" :key="item.id" inactive>
              <v-list-item-title
                >{{ item.fname }} {{ item.lname }}</v-list-item-title
              >
              <v-btn
                @click="sendMessage(item)"
                v-if="item.phoneNumber && item.phoneNumber !== 'N/A'"
                color="primary"
                x-small
                icon
              >
                <v-icon>mdi-message-arrow-right-outline</v-icon>
              </v-btn>

              <v-btn @click="startCall(item)" color="green" x-small text>
                <v-icon v-if="item.adHoc" x-small color="red" class="mr-2"
                  >mdi-checkbox-blank-circle</v-icon
                >
                {{ item.timeInCall }} min
                <v-icon right>mdi-phone-forward</v-icon>
              </v-btn>
            </v-list-item>
          </div>
          <div v-else>
            <v-list-item inactive>
              <v-list-item-content>
                <v-list-item-title>No patients waiting</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
          <v-list-item v-if="$mq === 'mobile'">
            <v-list-item-content>
              <div class="pa-2">
                <v-btn color="error" outlined block @click="signOut()"
                  >Logout</v-btn
                >
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <template v-if="$mq !== 'mobile'" v-slot:append>
        <div class="pa-2">
          <v-btn color="error" outlined block @click="signOut()">Logout</v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <Appbar :user="currentUser">
      <template
        v-if="$mq === 'mobile' || $mq === 'tablet' || $mq === 'tabletlg'"
        v-slot:drawer
      >
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      </template>
    </Appbar>

    <v-main>
      <v-container fluid>
        <v-dialog v-model="sendMessageDialog" max-width="500px">
          <SendTextMessageDialog
            :user="selectedMessage"
            @closeSendMessage="closeMessageDialog"
            :key="selectedMessage.userId"
          />
        </v-dialog>
        <slot :user="currentUser"> </slot>
      </v-container>
    </v-main>
    <FloatingActions style="position: fixed;" />
    <v-dialog
      v-if="inviteMessageDialog"
      v-model="inviteMessageDialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          Invite
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="inviteMessageDialog = false"
            >Cancel</v-btn
          >
        </v-card-title>
        <InviteMessageDialog
          @close="inviteMessageDialog = false"
          :user="currentUser"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Appbar from '@/components/Appbar'
import FloatingActions from '@/components/FloatingActions'
import * as moment from 'moment'
import * as fb from '../firebase'
import SendTextMessageDialog from '@/components/dialogs/SendTextMessageDialog'
import InviteMessageDialog from '@/components/dialogs/InviteMessageDialog'
import logoUH from '@/assets/logo.png'
import logoVP from '@/assets/logo.png'

export default {
  name: 'Home',
  props: ['user'],
  components: {
    Appbar,
    FloatingActions,
    SendTextMessageDialog,
    InviteMessageDialog
  },
  data() {
    return {
      fixed: false,
      drawer: true,
      miniVariant: false,
      expandOnHover: false,
      notificationCount: 3,
      waiting: [],
      appointments: [],
      selectedMessage: {},
      sendMessageDialog: false,
      siteMode: process.env.VUE_APP_SITE,
      logoUH: logoUH,
      logoVP: logoVP,
      currentUser: null,
      inviteMessageDialog: false
    }
  },
  watch: {
    user: {
      immediate: true,
      handler() {
        if (this.user) {
          this.currentUser = this.user
          this.$bind(
            'appointments',
            fb.db
              .collection('Practices')
              .doc(this.currentUser.practiceId)
              .collection('Appointments')
              .where('doctorId', '==', this.currentUser.id)
          )
        }
      }
    },
    appointments: function() {
      this.load = false
      this.waiting = []

      for (const item of this.appointments) {
        if (item.timeJoined) {
          const totalTime = new moment().diff(
            item.timeJoined.toDate(),
            'minutes'
          )
          if (totalTime > 180) {
            fb.db
              .collection('Practices')
              .doc(this.currentUser.practiceId)
              .collection('Appointments')
              .doc(item.id)
              .set({ isWaiting: false }, { merge: true })
          }
        }

        fb.patientsCollection
          .doc(item.patientId)
          .get()
          .then(snapshot => {
            const data = snapshot.data()
            if (
              item.isWaiting &&
              !this.waiting.some(x => x.id === snapshot.id)
            ) {
              this.waiting.push({
                ...data,
                isWaiting: item.isWaiting,
                url: item.url,
                path: item.path,
                adHoc: item.adHoc,
                id: snapshot.id,
                timeJoined: item.timeJoined,
                timeInCall: moment().diff(item.timeJoined.toDate(), 'minutes')
              })
            }
          })
      }
    },
    waiting: function() {
      setInterval(() => {
        for (const patient of this.waiting) {
          patient.timeInCall = moment().diff(
            patient.timeJoined.toDate(),
            'minutes'
          )
        }
      }, 1000)
    }
  },
  created() {
    if (
      this.$mq === 'mobile' ||
      this.$mq === 'tablet' ||
      this.$mq === 'tabletlg'
    ) {
      this.drawer = false
    }
  },
  methods: {
    signOut() {
      fb.auth.signOut()
      this.$router.go()
    },
    sendMessage(item) {
      this.selectedMessage = { phoneNumber: item.phoneNumber, userId: item.id }
      this.sendMessageDialog = true
    },
    closeMessageDialog() {
      this.sendMessageDialog = false
    },
    startCall(item) {
      // this.$router.push(this.$route.query.redirect || '/room' + item.path)

      let routeData = this.$router.resolve(
        this.$route.query.redirect || '/room' + item.path
      )

      window.open(routeData.href, '_self')
    }
  },
  computed: {}
}
</script>
